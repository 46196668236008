import getConfig from './config';
import getApiUrl from './api-url';
import getCookieDomain from './cookie-domain';
import getWLCookieDomain from './wl-cookie-domain';
import getBaseLanguageCodes from './base-language-codes';
import getLocaleCookie from './locale-cookie';
import getCurrentDomain from './current-domain';
import getSentryDSN from './sentry-dsn';
import getYolaEditorUrl from './yola-editor-url';
import getRecaptchaEnabled from './recaptcha-enabled';
import getIsB2C from './is-b2c';
import getDashboardURL from './dashboard-url';
import getYolaTemplateGalleryURL from './yola-template-gallery-url';
import getGTM from './gtm';
import getInvisibleRecaptchaSiteKey from './invisible-recaptcha-site-key';
import getFingerprintApiKey from './fingerprint-api-key';
import getFingerprintApiUrl from './fingerprint-api-url';
import getFingerprintCdnUrl from './fingerprint-cdn-url';
import getHelpCenterConfig from './help-center-config';
import getRequestCustomerSupportUrl from './requst-customer-support-url';

export default {
  getConfig,
  getApiUrl,
  getCookieDomain,
  getWLCookieDomain,
  getBaseLanguageCodes,
  getLocaleCookie,
  getCurrentDomain,
  getSentryDSN,
  getYolaEditorUrl,
  getRecaptchaEnabled,
  getIsB2C,
  getDashboardURL,
  getYolaTemplateGalleryURL,
  getGTM,
  getInvisibleRecaptchaSiteKey,
  getFingerprintApiKey,
  getFingerprintApiUrl,
  getFingerprintCdnUrl,
  getHelpCenterConfig,
  getRequestCustomerSupportUrl,
};
