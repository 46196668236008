import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import partner from 'src/js/modules/partner';
import Header from 'src/js/common/components/header';

const HeaderContainer = () => {
  const branding = useSelector(partner.selectors.getPartnerBranding, shallowEqual);
  const websiteUrl = useSelector(partner.selectors.getWebsiteURL) || '/';
  const { companyLogo, companyLogoWidth } = branding;

  return <Header link={websiteUrl} logoSrc={companyLogo} logoWidth={companyLogoWidth} />;
};

export default HeaderContainer;
