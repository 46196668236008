import { useEffect, useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import routesMap from 'src/js/router/helpers/routes-map';
import partner from 'src/js/modules/partner';

function PageTitleContainer() {
  const location = useLocation();
  const partnerName = useSelector(partner.selectors.getPartnerName);

  const titleName = useMemo(() => {
    const mapPathToTitle = {
      [routesMap.signup.pattern()]: i18next.t('Sign up'),
      [routesMap.resetPassword.pattern()]: i18next.t('Reset password'),
      [routesMap.forgotPassword.pattern()]: i18next.t('Forgot password'),
      [routesMap.logout.pattern()]: i18next.t('Logout'),
      [routesMap.index.pattern()]: i18next.t('Login'),
    };
    const path = Object.keys(mapPathToTitle).find((titlePath) =>
      matchPath(titlePath, location.pathname)
    );

    return mapPathToTitle[path] ? `${mapPathToTitle[path]} | ${partnerName}` : partnerName;
  }, [location.pathname, partnerName]);

  useEffect(() => {
    document.title = titleName;
  }, [titleName]);

  return null;
}

export default PageTitleContainer;
