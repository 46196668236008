import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routesMap from 'src/js/router/helpers/routes-map';
import PageTitleContainer from 'src/js/common/containers/page-title-container';
import SignupContainer from 'src/js/pages/containers/signup-container';
import LoginContainer from 'src/js/pages/containers/login-container';
import ForgotPasswordContainer from 'src/js/pages/containers/forgot-password-container';
import ResetPasswordContainer from 'src/js/pages/containers/reset-password-container';
import LogoutContainer from 'src/js/pages/containers/logout-container';
import EmailConfirmationContainer from 'src/js/pages/containers/email-confirmation-container';
import OverlayLoaderContainer from 'src/js/modules/overlay-loader/containers/overlay-loader-container';
import TagManagerContainer from 'src/js/modules/analytics/tag-manager/containers/tag-manager-container';
import UnusualActivityContainer from 'src/js/pages/containers/unusual-activity-container';
import config from './modules/config';
import i18n from './modules/i18n';

function App() {
  const configData = useSelector(config.selectors.getConfig);
  const locale = useSelector(i18n.selectors.getLocale);
  const supportedLocales = useSelector(config.selectors.getBaseLanguageCodes);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const URLWithoutLocale = i18n.helpers.getURLWithoutLocale(location.pathname, supportedLocales);
    if (URLWithoutLocale === location.pathname) return;

    navigate(URLWithoutLocale, { replace: true });
  }, [location.pathname, navigate, supportedLocales]);

  if (!configData || !locale) return null;

  return (
    <React.Fragment>
      <Routes>
        <Route path={routesMap.signup.pattern()} element={<SignupContainer />} />
        <Route path={routesMap.resetPassword.pattern()} element={<ResetPasswordContainer />} />
        <Route path={routesMap.forgotPassword.pattern()} element={<ForgotPasswordContainer />} />
        <Route path={routesMap.logout.pattern()} element={<LogoutContainer />} />
        <Route
          path={routesMap.emailConfirmation.pattern()}
          element={<EmailConfirmationContainer />}
        />
        <Route path={routesMap.unusualActivity.pattern()} element={<UnusualActivityContainer />} />
        <Route path={routesMap.index.pattern()} element={<LoginContainer />} />
      </Routes>
      <PageTitleContainer />
      <OverlayLoaderContainer />
      <TagManagerContainer />
    </React.Fragment>
  );
}

export default App;
