import get from 'lodash.get';
import getIsB2C from './is-b2c';

const getInvisibleRecaptchaSiteKey = (rootState) => {
  if (getIsB2C(rootState)) {
    return get(rootState, 'config.common.recaptcha_v2.b2c_invisible.site_key');
  }

  return get(rootState, 'config.common.recaptcha_v2.wl_invisible.site_key');
};

export default getInvisibleRecaptchaSiteKey;
