import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import user from 'src/js/modules/user';
import routesMap from 'src/js/router/helpers/routes-map';
import Page from 'src/js/common/components/page';
import LogoutProgressContainer from './logout-progress-container';

const { Heading, Paragraph, Stack, ActionButton } = designSystem;

function LogoutContainer() {
  const isAuthenticated = useSelector(user.verifiers.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(isAuthenticated);

  useEffect(() => {
    if (loggedIn) {
      user.operations.logout().then(() => {
        setLoggedIn(false);
      });
    }
  }, [loggedIn, dispatch]);

  const captions = {
    title: i18next.t('Logged Out'),
    subtitle: i18next.t('You are now logged out of your account'),
    buttons: {
      login: i18next.t('Log in'),
      signup: i18next.t('Sign up'),
    },
  };

  if (loggedIn) {
    return <LogoutProgressContainer />;
  }

  return (
    <Page>
      <Stack gap="spacing-m">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>

        <Stack gap="spacing-3-xs">
          <ActionButton
            appearance="default"
            format="outline"
            size="large"
            onClick={() => navigate(routesMap.index.url())}
            label={captions.buttons.login}
          />
          <ActionButton
            appearance="cta"
            format="solid"
            size="large"
            onClick={() => navigate(routesMap.signup.url())}
            label={captions.buttons.signup}
          />
        </Stack>
      </Stack>
    </Page>
  );
}

export default LogoutContainer;
