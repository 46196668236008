import getSearchParamsString from 'src/js/utils/get-search-params-string';

export default {
  index: {
    pattern: () => `/`,
    url: () => `/${getSearchParamsString()}`,
  },
  signup: {
    pattern: () => `/signup`,
    url: () => `/signup${getSearchParamsString()}`,
  },
  resetPassword: {
    pattern: () => `/reset-password`,
    url: () => `/reset-password${getSearchParamsString()}`,
  },
  forgotPassword: {
    pattern: () => `/forgot-password`,
    url: () => `/forgot-password${getSearchParamsString()}`,
  },
  logout: {
    pattern: () => `/logout`,
    url: () => `/logout${getSearchParamsString()}`,
  },
  emailConfirmation: {
    pattern: () => `/email-confirmation`,
    url: () => `/email-confirmation${getSearchParamsString()}`,
  },
  unusualActivity: {
    pattern: () => `/unusual-activity-detected`,
    url: () => `/unusual-activity-detected${getSearchParamsString()}`,
  },
};
