import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Heading, Paragraph, ActionButton, Box } = designSystem;

const WarningBanner = ({ captions, onButtonClick }) => (
  <div className="ws-warning-banner">
    <Box padding="spacing-m">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.6748 14.7196C30.1529 12.1601 33.8473 12.1601 35.3254 14.7196L52.9934 45.3132C54.4718 47.8732 52.6243 51.0736 49.6681 51.0736H14.3321C11.3758 51.0736 9.52833 47.8732 11.0067 45.3132L28.6748 14.7196Z"
          fill="#FFC107"
        />
        <path
          d="M31.0467 38.5555C30.8805 38.5555 30.7392 38.5056 30.6228 38.4059C30.5064 38.2895 30.4482 38.1398 30.4482 37.957V26.5351C30.4482 26.3688 30.5064 26.2275 30.6228 26.1111C30.7392 25.9947 30.8805 25.9365 31.0467 25.9365H33.0668C33.2497 25.9365 33.391 25.9947 33.4907 26.1111C33.6071 26.2275 33.6653 26.3688 33.6653 26.5351V37.957C33.6653 38.1398 33.6071 38.2895 33.4907 38.4059C33.391 38.5056 33.2497 38.5555 33.0668 38.5555H31.0467ZM30.8971 43.3936C30.7142 43.3936 30.5646 43.3354 30.4482 43.219C30.3485 43.1026 30.2986 42.9613 30.2986 42.7951V40.4758C30.2986 40.2929 30.3485 40.1433 30.4482 40.0269C30.5646 39.9105 30.7142 39.8523 30.8971 39.8523H33.2164C33.3993 39.8523 33.5489 39.9105 33.6653 40.0269C33.7817 40.1433 33.8399 40.2929 33.8399 40.4758V42.7951C33.8399 42.9613 33.7817 43.1026 33.6653 43.219C33.5489 43.3354 33.3993 43.3936 33.2164 43.3936H30.8971Z"
          fill="#4B4A4D"
        />
      </svg>
      <Heading type="heading-5" align="center">
        {captions.title}
      </Heading>
      <Paragraph align="center" appearance="medium-emphasis">
        {captions.description}
      </Paragraph>
      <Box marginTop="spacing-s">
        <ActionButton label={captions.button} format="solid" onClick={onButtonClick} />
      </Box>
    </Box>
  </div>
);

WarningBanner.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default WarningBanner;
