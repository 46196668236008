import yousersjs from '@yola/yousersjs';
import partner from 'src/js/modules/partner';
import analytics from 'src/js/modules/analytics';

const login = (userData) => async () => {
  let visitorFingerprint = null;
  let fingerprintRequestId = null;

  try {
    const { visitorId, requestId } = await analytics.fingerprint.getVisitorData();

    visitorFingerprint = visitorId;
    fingerprintRequestId = requestId;
  } catch (error) {
    console.error('[FP] Get data:', error);
  }

  const data = {
    ...userData,
    vfp: visitorFingerprint,
    fprid: fingerprintRequestId,
    partner_id: partner.accessors.getPartnerId(),
  };

  return yousersjs.requests.login(data);
};

export default login;
