import store from 'src/js/store';
import config from 'src/js/modules/config';
import i18n from 'src/js/modules/i18n';
import user from 'src/js/modules/user';
import analytics from 'src/js/modules/analytics';
import partner from 'src/js/modules/partner';
import redirectToUrl from 'src/js/utils/redirect-to-url';

const initApp = async () => {
  await store.dispatch(config.thunks.fetchConfig());

  user.helpers.configureYousersJS();
  analytics.sentry.instance.init(config.selectors.getSentryDSN(store.getState()));
  analytics.tagManager.init(config.selectors.getGTM(store.getState()));

  analytics.fingerprint.init({
    apiKey: config.selectors.getFingerprintApiKey(store.getState()),
    cdnUrl: config.selectors.getFingerprintCdnUrl(store.getState()),
    apiUrl: config.selectors.getFingerprintApiUrl(store.getState()),
  });

  const partnerId = partner.accessors.getPartnerId();

  if (!partnerId) {
    redirectToUrl('/errors/403.html');
  }

  await store.dispatch(partner.thunks.fetchPartnerData(partnerId));

  const fetchedPartnerData = partner.selectors.getPartnerData(store.getState());
  // fetchedPartnerData condition is needed to check is specified partner exist or not
  // fetchedPartnerData.id !== partnerId is needed to handle cases when `pid` query parameter has
  // slashes in the beginning or in the end of parnerId, like app-url/signup?pid=YOLA/
  if (
    !fetchedPartnerData ||
    fetchedPartnerData.id !== partnerId ||
    !partner.verifiers.isAppAllowedByPartner()
  ) {
    redirectToUrl('/errors/403.html');
  }

  partner.helpers.savePartnerId(partnerId);

  await Promise.all([
    i18n.initializers.initializeI18next(),
    store.dispatch(i18n.thunks.loadTranslations()),
  ]);
};

export default initApp;
