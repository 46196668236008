import * as Sentry from '@sentry/browser';

let sentryIsInitialized = false;

const init = (dsn) => {
  Sentry.init({
    dsn,
  });
  sentryIsInitialized = true;
};
const isInit = () => sentryIsInitialized;
const getInstance = () => Sentry;
const captureException = (error, options = {}) => {
  const { captureContext, extraData } = options;

  const capture = () => Sentry.captureException(error, captureContext);

  if (extraData) {
    Sentry.withScope((scope) => {
      scope.setExtras(extraData);

      capture();
    });

    return;
  }

  capture();
};

export default {
  init,
  getInstance,
  isInit,
  captureException,
};
