import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { logoSrc, logoWidth, logoHeight, link } = props;

  return (
    <header className="ws-header">
      <div className="ws-header__inner">
        <a href={link} className="ws-header__logo">
          <img src={logoSrc} width={logoWidth} height={logoHeight} alt="Logo" />
        </a>
      </div>
    </header>
  );
};

Header.propTypes = {
  link: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  logoWidth: PropTypes.number.isRequired,
  logoHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Header.defaultProps = {
  logoHeight: 'auto',
};

export default Header;
