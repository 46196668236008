import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import config from 'src/js/modules/config';
import SplitPageView from 'src/js/common/components/split-page-view';
import WarningBanner from 'src/js/pages/components/warning-banner';
import redirectToUrl from 'src/js/utils/redirect-to-url';

function UnusualActivityContainer() {
  const requestCustomerSupportURL = useSelector(config.selectors.getRequestCustomerSupportUrl);

  const captions = {
    title: i18next.t('Unusual activity detected'),
    description: i18next.t(
      'Our systems have detected unusual activity from your account. Please contact customer support for further assistance.'
    ),
    button: i18next.t('Contact support'),
  };

  const onButtonClick = useCallback(() => {
    redirectToUrl(requestCustomerSupportURL);
  }, [requestCustomerSupportURL]);

  return (
    <SplitPageView
      images={{
        x1: '/images/signup-cover-image@1x.png',
        x2: '/images/signup-cover-image@2x.png',
      }}
    >
      <WarningBanner captions={captions} onButtonClick={onButtonClick} />
    </SplitPageView>
  );
}

export default UnusualActivityContainer;
