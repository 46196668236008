import React, { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import i18n from 'src/js/modules/i18n';
import user from 'src/js/modules/user';
import config from 'src/js/modules/config';
import routesMap from 'src/js/router/helpers/routes-map';
import overlayLoader from 'src/js/modules/overlay-loader';
import SplitPageView from 'src/js/common/components/split-page-view';
import redirectToNext from 'src/js/common/helpers/redirect-to-next';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import withAuthRedirect from '../hoc/with-auth-redirect';
import useTimer from '../hooks/use-timer';
import LoginForm from '../components/login-form';
import LoginPasswordExpiry from '../components/login-password-expiry';

const errorCodes = {
  PASSWORD_EXPIRED: 'password_expired',
  THROTTLED: 'throttled',
};
const getCaptions = () => ({
  title: i18next.t('Welcome back'),
  subtitle: i18next.t('Please enter your details'),
  email: {
    label: i18next.t('Email'),
    placeholder: i18next.t('Enter your email'),
  },
  password: { label: i18next.t('Password'), placeholder: i18next.t('Enter your password') },
  checkbox: {
    label: i18next.t('Remember me'),
  },
  button: {
    label: i18next.t('Log in'),
  },
  forgotPassword: {
    label: i18next.t('Forgot password?'),
  },
  tip: {
    text: i18next.t('Don’t have an account?'),
    link: i18next.t('Sign up!'),
  },
  loader: {
    progress: i18next.t('Logging in. Please wait...'),
    success: i18next.t('Login successful. Preparing your dashboard...'),
  },
  passwordExpiry: {
    title: i18next.t('Your password has expired.'),
    descriptions: i18next.t('For your security, please reset your password to log in. Need help? '),
    tutorial: i18next.t('View our tutorial'),
    button: { label: i18next.t('Reset password') },
  },
});

function LoginContainer() {
  const dispatch = useDispatch();
  const dashboardURL = useSelector(config.selectors.getDashboardURL);
  const siteKey = useSelector(config.selectors.getInvisibleRecaptchaSiteKey);
  const isRecaptchaEnabled = useSelector(config.selectors.getRecaptchaEnabled);
  const helpCenterConfig = useSelector(config.selectors.getHelpCenterConfig);
  const isB2C = useSelector(config.selectors.getIsB2C);
  const locale = useSelector(i18n.selectors.getLocale);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPasswordExpiry, setShowPasswordExpiry] = useState(false);

  const captions = getCaptions();

  const navigate = useNavigate();
  const recaptchaRef = useRef();
  const timer = useTimer();

  const showLoader = useCallback((msg) => dispatch(overlayLoader.actions.show(msg)), [dispatch]);
  const hideLoader = useCallback(() => dispatch(overlayLoader.actions.hide()), [dispatch]);

  const clearErrorMessage = () => setErrorMessage(null);

  const handleLoginError = async (response) => {
    const json = await response.json();
    const { code, wait } = json;

    switch (code) {
      case errorCodes.PASSWORD_EXPIRED: {
        if (isB2C) {
          setShowPasswordExpiry(true);
          break;
        }

        setErrorMessage(
          i18next.t(
            'Your password has expired. <br />To log in, please <a href="{resetUrl}">reset password</a>',
            { resetUrl: routesMap.forgotPassword.url() }
          )
        );
        break;
      }
      case errorCodes.THROTTLED: {
        const showThrottleErrorMessage = ({ totalMinutes, totalSeconds }) => {
          const timeUnit = totalMinutes ? i18next.t('minutes') : i18next.t('seconds');
          const minutesToShow = totalMinutes + 1;

          setErrorMessage(
            i18next.t(
              'We are sorry, but you have sent too many login requests to us recently. Please try again in {time} {timeUnit}',
              { time: minutesToShow > 1 ? minutesToShow : totalSeconds, timeUnit }
            )
          );
        };

        timer.run(wait, showThrottleErrorMessage, clearErrorMessage);
        break;
      }
      default: {
        setErrorMessage(i18next.t('Invalid login or password'));
      }
    }
  };

  const validateFields = () => {
    const errors = {};
    let isValid = true;
    if (email.trim().length === 0) {
      errors.email = i18next.t('Field is required');
      isValid = false;
    }
    if (password.trim().length === 0) {
      errors.password = i18next.t('Field is required');
      isValid = false;
    }
    setFieldErrors(errors);
    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    timer.clear();

    const data = {
      email,
      password,
    };

    if (isRecaptchaEnabled) {
      data.captcha = await recaptchaRef.current.executeAsync();
    }

    if (rememberMe) {
      data.remember_me = '1';
    }

    clearErrorMessage();
    const isValid = validateFields();
    if (!isValid) {
      recaptchaRef.current?.reset();

      return;
    }

    showLoader(captions.loader.progress);

    try {
      await dispatch(user.thunks.login(data));
      showLoader(captions.loader.success);
      const redirectedToNext = redirectToNext();

      if (!redirectedToNext) {
        redirectToUrl(dashboardURL);
      }
    } catch (error) {
      recaptchaRef.current?.reset();
      hideLoader();
      await handleLoginError(error.response);
    }
  };

  const onResetPasswordClick = () => navigate(routesMap.forgotPassword.url());

  return (
    <SplitPageView
      images={{
        x1: '/images/login-cover-image@1x.png',
        x2: '/images/login-cover-image@2x.png',
      }}
    >
      {showPasswordExpiry ? (
        <LoginPasswordExpiry
          captions={captions.passwordExpiry}
          tutorialUrl={helpCenterConfig.password_expiry_and_reset_url}
          onResetPasswordClick={onResetPasswordClick}
        />
      ) : (
        <LoginForm
          captions={captions}
          fieldErrors={fieldErrors}
          errorMessage={errorMessage}
          onSubmit={onSubmit}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          isRecaptchaEnabled={isRecaptchaEnabled}
          recaptchaRef={recaptchaRef}
          siteKey={siteKey}
          locale={locale}
        />
      )}
    </SplitPageView>
  );
}

export default withAuthRedirect(LoginContainer);
