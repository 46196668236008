import React from 'react';
import PropTypes from 'prop-types';
import { designSystem } from '@yola/ws-ui';

const { Heading, Paragraph, Stack, ActionButton, Link, Box } = designSystem;

const LoginPasswordExpiry = ({ captions, onResetPasswordClick, tutorialUrl }) => (
  <Box marginTop="spacing-2-xl">
    <div className="ws-login-password-expiry">
      <div className="ws-login-password-expiry__image">
        <img src="/images/login-password-expiry.svg" alt="" />
      </div>
      <Stack>
        <Heading type="heading-5" align="center">
          {captions.title}
        </Heading>
        <Paragraph align="center">
          {captions.descriptions}
          <Link isUnderlined href={tutorialUrl} appearance="accent">
            {captions.tutorial}
          </Link>
        </Paragraph>
      </Stack>
      <ActionButton
        format="solid"
        size="large"
        label={captions.button.label}
        onClick={onResetPasswordClick}
      />
    </div>
  </Box>
);

LoginPasswordExpiry.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    descriptions: PropTypes.string,
    tutorial: PropTypes.string,
    button: PropTypes.shape({ label: PropTypes.string }),
  }).isRequired,
  onResetPasswordClick: PropTypes.func.isRequired,
  tutorialUrl: PropTypes.string.isRequired,
};

export default LoginPasswordExpiry;
