import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { designSystem } from '@yola/ws-ui';
import ReCAPTCHA from 'react-google-recaptcha';
import routesMap from 'src/js/router/helpers/routes-map';
import config from 'src/js/modules/config';
import i18n from 'src/js/modules/i18n';
import user from 'src/js/modules/user';
import partner from 'src/js/modules/partner';
import overlayLoader from 'src/js/modules/overlay-loader';
import redirectToNext from 'src/js/common/helpers/redirect-to-next';
import Label from 'src/js/common/components/label';
import SplitPageView from 'src/js/common/components/split-page-view';
import redirectToUrl from 'src/js/utils/redirect-to-url';
import withAuthRedirect from '../hoc/with-auth-redirect';

const {
  Heading,
  Paragraph,
  InputGroup,
  Stack,
  Checkbox,
  ActionButton,
  FieldStatus,
  NotificationMessage,
} = designSystem;

const errorCodes = {
  INVALID_PARTNER_ID: 'invalid_partner_id',
  UNUSUAL_ACTIVITY_DETECTED: 'unusual_activity_detected',
};

function SignupContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partnerName = useSelector(partner.selectors.getPartnerName);
  const galleryURL = useSelector(config.selectors.getYolaTemplateGalleryURL);
  const siteKey = useSelector(config.selectors.getInvisibleRecaptchaSiteKey);
  const locale = useSelector(i18n.selectors.getLocale);
  const isRecaptchaEnabled = useSelector(config.selectors.getRecaptchaEnabled);
  const privacyPolicyURL = useSelector(partner.selectors.getPrivacyPolicyURL);
  const tosURL = useSelector(partner.selectors.getTosURL);

  const captions = useMemo(
    () => ({
      title: i18next.t('Get started'),
      subtitle: i18next.t('Make your beautiful website with {partnerName}!', {
        partnerName,
      }),
      checkbox: {
        label: i18next.t(
          `<mark>I agree to the</mark> <a href="{terms}">Terms of Service</a> <mark>and</mark> <a href="{privacy}">Privacy Policy.</a>`,
          {
            terms: tosURL,
            privacy: privacyPolicyURL,
          }
        ),
      },
      button: {
        label: i18next.t('Start Building Your Website'),
      },
      tip: {
        text: i18next.t('Already have an account?'),
        link: i18next.t('Log in'),
      },
      loader: {
        progress: i18next.t('Creating account. Please wait...'),
        success: i18next.t('Sign up successful. Preparing Template Gallery for you...'),
      },
    }),
    [partnerName, tosURL, privacyPolicyURL]
  );

  const showTermsCheckbox = Boolean(privacyPolicyURL || tosURL);
  const recaptchaRef = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const showLoader = useCallback((msg) => dispatch(overlayLoader.actions.show(msg)), [dispatch]);
  const hideLoader = useCallback(() => dispatch(overlayLoader.actions.hide()), [dispatch]);

  const handleSignupErrors = async (response) => {
    const defaultErrorMessage = i18next.t(
      'Please try again. There was an issue processing your sign up. Please try again or contact our friendly support team.'
    );
    if (response.status === 500) {
      setErrorMessage(defaultErrorMessage);
      return;
    }

    const json = await response.json();
    const { code } = json;

    recaptchaRef.current?.reset();

    switch (response.status) {
      case 409: {
        setErrorMessage(i18next.t('A user with this email already exists'));
        break;
      }
      case 400: {
        if (code === errorCodes.INVALID_PARTNER_ID) {
          setErrorMessage(defaultErrorMessage);
          return;
        }

        setErrorMessage(i18next.t('Please correct the mentioned fields'));
        const { fields } = json;
        const errors = {};

        if (!fields) return;

        if (Object.prototype.hasOwnProperty.call(fields, 'email')) {
          errors.email = i18next.t('Enter a valid email address');
        }

        if (Object.prototype.hasOwnProperty.call(fields, 'password')) {
          errors.password = i18next.t('Ensure this field has at least 8 characters');
        }

        setFieldErrors(errors);
        break;
      }
      case 429: {
        setErrorMessage(
          i18next.t("Sorry, we're getting too many signups at the moment. Please try again later.")
        );
        break;
      }
      case 403: {
        if (code === errorCodes.UNUSUAL_ACTIVITY_DETECTED) {
          navigate(routesMap.unusualActivity.url());
          return;
        }

        setErrorMessage(defaultErrorMessage);
        break;
      }
      default: {
        setErrorMessage(defaultErrorMessage);
        break;
      }
    }
  };

  const validateFields = () => {
    const errors = {};
    let isValid = true;
    if (name.trim().length === 0) {
      errors.name = i18next.t('Field is required');
      isValid = false;
    }
    if (email.trim().length === 0) {
      errors.email = i18next.t('Field is required');
      isValid = false;
    }
    if (password.trim().length === 0) {
      errors.password = i18next.t('Field is required');
      isValid = false;
    }

    setFieldErrors(errors);
    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = {
      name,
      email,
      password,
    };

    if (isRecaptchaEnabled) {
      data.captcha = await recaptchaRef.current.executeAsync();
    }

    if (showTermsCheckbox) {
      data.terms_and_policy = terms ? 'on' : 'off';
    }

    setErrorMessage(null);
    const isValid = validateFields();

    if (!isValid) {
      recaptchaRef.current?.reset();

      return;
    }

    showLoader(captions.loader.progress);

    try {
      await dispatch(user.thunks.register(data));
      showLoader(captions.loader.success);
      await dispatch(user.thunks.login(data));
      const redirectedToNext = redirectToNext();

      if (!redirectedToNext) {
        redirectToUrl(galleryURL);
      }
    } catch (error) {
      recaptchaRef.current?.reset();
      hideLoader();
      await handleSignupErrors(error);
    }
  };

  const termsAndPolicyCheckboxLabel = (
    <Paragraph appearance="accent">
      <span
        // eslint-disable-next-line yola/react/no-danger
        dangerouslySetInnerHTML={{ __html: captions.checkbox.label }}
      />
    </Paragraph>
  );

  return (
    <SplitPageView
      images={{
        x1: '/images/signup-cover-image@1x.png',
        x2: '/images/signup-cover-image@2x.png',
      }}
    >
      <Stack gap="spacing-m">
        <Stack gap="spacing-3-xs">
          <Heading type="heading-2" align="center">
            {captions.title}
          </Heading>
          <Paragraph align="center" appearance="medium-emphasis">
            {captions.subtitle}
          </Paragraph>
        </Stack>

        {errorMessage && (
          <NotificationMessage align="center" appearance="danger" title={errorMessage} />
        )}

        <form onSubmit={onSubmit}>
          <Stack gap="spacing-xs">
            <Stack gap="spacing-3-xs">
              <Label id="name" required>
                {i18next.t('Name')}
              </Label>
              <InputGroup
                id="name"
                name="name"
                iconGlyph="user"
                size="large"
                value={name}
                invalid={Boolean(fieldErrors.name)}
                onChange={setName}
                placeholder={i18next.t('Enter your name')}
              />
              {fieldErrors.name && <FieldStatus appearance="error" text={fieldErrors.name} />}
            </Stack>
            <Stack gap="spacing-3-xs">
              <Label id="email" required>
                {i18next.t('Email')}
              </Label>
              <InputGroup
                id="email"
                name="email"
                iconGlyph="mail"
                size="large"
                value={email}
                invalid={Boolean(fieldErrors.email)}
                onChange={setEmail}
                placeholder={i18next.t('Enter your email')}
              />
              {fieldErrors.email && <FieldStatus appearance="error" text={fieldErrors.email} />}
            </Stack>
            <Stack gap="spacing-4-xs">
              <Label id="password" required>
                {i18next.t('Password')}
              </Label>
              <InputGroup
                id="password"
                name="password"
                iconGlyph="lock"
                size="large"
                type="password"
                value={password}
                invalid={Boolean(fieldErrors.password)}
                onChange={setPassword}
                placeholder={i18next.t('Enter your password')}
              />
              {fieldErrors.password && (
                <FieldStatus appearance="error" text={fieldErrors.password} />
              )}
            </Stack>
            {showTermsCheckbox && (
              <Checkbox
                id="terms_and_policy"
                name="terms_and_policy"
                label={termsAndPolicyCheckboxLabel}
                value={terms ? '1' : '0'}
                isLabelBold={false}
                onChange={setTerms}
              />
            )}
            <Stack gap="spacing-m">
              {isRecaptchaEnabled && (
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} hl={locale} />
              )}
              <ActionButton
                appearance="cta"
                format="solid"
                size="large"
                label={captions.button.label}
                disabled={showTermsCheckbox ? !terms : false}
                type="submit"
              />
              <div className="ws-text-accent-link-modifier">
                <Paragraph align="center">
                  {captions.tip.text} <Link to={routesMap.index.url()}>{captions.tip.link}!</Link>
                </Paragraph>
              </div>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </SplitPageView>
  );
}

export default withAuthRedirect(SignupContainer);
