import getPartnerData from './partner-data';
import getAvailablePlatformComponents from './available-platform-components';
import getPartnerBranding from './partner-branding';
import getPartnerName from './partner-name';
import getPrivacyPolicyURL from './privacy-policy-url';
import getTosURL from './tos-url';
import getDpaURL from './dpa-url';
import getWebsiteURL from './website-url';

export default {
  getPartnerData,
  getAvailablePlatformComponents,
  getPartnerBranding,
  getPartnerName,
  getPrivacyPolicyURL,
  getTosURL,
  getDpaURL,
  getWebsiteURL,
};
