import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { designSystem } from '@yola/ws-ui';
import routesMap from 'src/js/router/helpers/routes-map';
import Label from 'src/js/common/components/label';

const {
  Heading,
  Paragraph,
  InputGroup,
  Stack,
  Checkbox,
  ActionButton,
  Row,
  Col,
  FieldStatus,
  NotificationMessage,
} = designSystem;

const LoginForm = ({
  captions,
  fieldErrors,
  errorMessage,
  onSubmit,
  email,
  setEmail,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  isRecaptchaEnabled,
  recaptchaRef,
  siteKey,
  locale,
}) => (
  <Stack gap="spacing-m">
    <Stack gap="spacing-3-xs">
      <Heading type="heading-2" align="center">
        {captions.title}
      </Heading>
      <Paragraph align="center" appearance="medium-emphasis">
        {captions.subtitle}
      </Paragraph>
    </Stack>

    {errorMessage && (
      <NotificationMessage
        align="center"
        appearance="danger"
        title={
          <Paragraph appearance="danger">
            <span
              // eslint-disable-next-line yola/react/no-danger
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          </Paragraph>
        }
      />
    )}

    <form onSubmit={onSubmit}>
      <Stack gap="spacing-xs">
        <Stack gap="spacing-3-xs">
          <Label id="email" required>
            {captions.email.label}
          </Label>
          <InputGroup
            id="email"
            name="email"
            iconGlyph="mail"
            size="large"
            value={email}
            invalid={Boolean(fieldErrors.email || errorMessage)}
            onChange={setEmail}
            placeholder={captions.email.placeholder}
          />
          {fieldErrors.email && <FieldStatus appearance="error" text={fieldErrors.email} />}
        </Stack>
        <Stack gap="spacing-3-xs">
          <Label id="password" required>
            {captions.password.label}
          </Label>
          <InputGroup
            id="password"
            name="password"
            iconGlyph="lock"
            size="large"
            type="password"
            value={password}
            invalid={Boolean(fieldErrors.password || errorMessage)}
            onChange={setPassword}
            placeholder={captions.password.placeholder}
          />
          {fieldErrors.password && <FieldStatus appearance="error" text={fieldErrors.password} />}
        </Stack>
        <Stack gap="spacing-m">
          <Row>
            <Col>
              <Checkbox
                id="remember_me"
                name="remember_me"
                label={captions.checkbox.label}
                value={rememberMe ? '1' : '0'}
                isLabelBold={false}
                onChange={setRememberMe}
              />
            </Col>
            <Col>
              <div className="ws-text-accent-link-modifier">
                <Paragraph appearance="accent" size="medium" align="right">
                  <Link to={routesMap.forgotPassword.url()}>{captions.forgotPassword.label}</Link>
                </Paragraph>
              </div>
            </Col>
          </Row>
          {isRecaptchaEnabled && (
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey} hl={locale} />
          )}
          <ActionButton
            appearance="accent"
            format="solid"
            size="large"
            type="submit"
            label={captions.button.label}
            onClick={() => {}}
          />
          <div className="ws-text-accent-link-modifier">
            <Paragraph align="center">
              {captions.tip.text} <Link to={routesMap.signup.url()}>{captions.tip.link}</Link>
            </Paragraph>
          </div>
        </Stack>
      </Stack>
    </form>
  </Stack>
);

LoginForm.propTypes = {
  captions: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    email: PropTypes.shape({ label: PropTypes.string, placeholder: PropTypes.string }),
    password: PropTypes.shape({ label: PropTypes.string, placeholder: PropTypes.string }),
    checkbox: PropTypes.shape({ label: PropTypes.string }),
    forgotPassword: PropTypes.shape({ label: PropTypes.string }),
    button: PropTypes.shape({ label: PropTypes.string }),
    tip: PropTypes.shape({ text: PropTypes.string, link: PropTypes.string }),
  }).isRequired,
  fieldErrors: PropTypes.shape().isRequired,
  errorMessage: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  rememberMe: PropTypes.bool.isRequired,
  recaptchaRef: PropTypes.shape().isRequired,
  isRecaptchaEnabled: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  setRememberMe: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  errorMessage: '',
  email: '',
  password: '',
};

export default LoginForm;
