import get from 'lodash.get';
import getHelpCenterUrl from './helpcenter-url';

const getRequestCustomerSupportUrl = (rootState) => {
  const helpcenterUrl = getHelpCenterUrl(rootState);
  const requestSupportPath = get(
    rootState,
    'config.common.ws-helpcenter.request_customer_support_path'
  );

  return new URL(requestSupportPath, helpcenterUrl).href;
};

export default getRequestCustomerSupportUrl;
