/**
 * Removes the locale from the given relative URL.
 * @param {string} url - The relative URL from which to remove the locale.
 * @param {Array<string>} supportedLocales - An array of supported locale strings.
 * @returns {string} - The relative URL without the locale.
 */
const getURLWithoutLocale = (url, supportedLocales) => {
  const pathSegments = url.split('/').filter(Boolean);

  if (supportedLocales.includes(pathSegments[0])) {
    pathSegments.shift();
  }

  return `/${pathSegments.join('/')}`;
};

export default getURLWithoutLocale;
