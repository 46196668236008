import { FpjsClient, FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-spa';
import sentry from '../sentry';

let isInitialized = false;
let isVisitorDataReceived = false;
let initializePromise = null;
let instance;

const getExecutionInformation = (options = {}) => ({
  isFPInitialized: isInitialized,
  isFPVisitorDataReceived: isVisitorDataReceived,
  ...options,
});

const init = ({ apiKey, cdnUrl, apiUrl }) => {
  const endpoint = [FingerprintJSPro.defaultEndpoint];
  const scriptUrlPattern = [FingerprintJSPro.defaultScriptUrlPattern];

  if (apiUrl) {
    endpoint.unshift(apiUrl);
  }

  if (cdnUrl) {
    scriptUrlPattern.unshift(
      `${cdnUrl}?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`
    );
  }

  instance = new FpjsClient({
    loadOptions: {
      apiKey,
      endpoint,
      scriptUrlPattern,
    },
  });

  initializePromise = instance
    .init()
    .then(() => {
      isInitialized = true;
    })
    .catch((error) => {
      sentry.instance.captureException(error, {
        extraData: getExecutionInformation(),
      });
      throw error;
    });
};

const getVisitorData = async () => {
  if (!isInitialized && initializePromise) {
    try {
      await initializePromise;
    } catch (error) {
      console.error('[FP] Initialization:', error);
      throw error;
    }
  }

  try {
    const visitorData = await instance.getVisitorData();

    if (!visitorData || !visitorData.visitorId || !visitorData.requestId) {
      sentry.instance.captureException(new Error('[FP] Required visitor data is missing'), {
        extraData: getExecutionInformation({
          visitorData: JSON.stringify(visitorData),
        }),
      });
    }

    isVisitorDataReceived = true;

    return visitorData;
  } catch (error) {
    sentry.instance.captureException(error, {
      extraData: getExecutionInformation(),
    });

    throw error;
  }
};

export default {
  getVisitorData,
  init,
};
