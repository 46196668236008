import { useRef, useEffect } from 'react';

const timerDelay = 1 * 1000; // 1 second

const useTimer = () => {
  const intervalIdRef = useRef(null);

  const clearTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  };

  useEffect(
    () => () => {
      clearTimer();
    },
    [intervalIdRef]
  );

  return {
    run: (secondsToWait = 80, showMessage, clearMessage) => {
      const timerEnd = Date.now() + secondsToWait * 1000;

      const getTimeToWait = () => {
        const totalSeconds = Math.floor((timerEnd - Date.now()) / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);

        return { totalSeconds, totalMinutes };
      };

      showMessage(getTimeToWait());
      intervalIdRef.current = setInterval(() => {
        const timeToWait = getTimeToWait();
        showMessage(timeToWait);

        if (timeToWait.totalSeconds < 0) {
          clearTimer();
          clearMessage();
        }
      }, timerDelay);
    },
    clear: clearTimer,
  };
};

export default useTimer;
